<script>
import appConfig from "@/app.config";

import {mapState} from "vuex";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum
} from "vuelidate/lib/validators";

export default {
  components: {

  },
  data() {
    return {
      password: "",
      confirmPassword: ""
    };
  },
  validations: {
    password: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAsPassword: sameAs("password") },
    form: ['password', 'confirmPassword']
  },
  created() {

  },
  methods: {
    validate() {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    }
  }
};
</script>
<template>
  <div class="form-group">
    <label>
      {{ $t('account.security.password.prompt') }}
    </label>
    <div>
      <input
          v-model="password"
          type="password"
          name="password"
          class="form-control"
          :class="{ 'is-invalid': $v.password.$error }"
          :placeholder="$t('account.security.password.process.prompt')"
      />
      <div v-if="$v.password.$error" class="invalid-feedback">
        <span v-if="!$v.password.required">
          {{ $t('account.security.password.process.error.required') }}
        </span>
        <span v-if="!$v.password.minLength">
          {{ $t('account.security.password.process.error.length') }}
        </span>
      </div>
    </div>
    <div class="mt-2">
      <input
          v-model="confirmPassword"
          type="password"
          name="confirmPassword"
          class="form-control"
          :class="{ 'is-invalid': $v.confirmPassword.$error }"
          :placeholder="$t('account.security.password.process.retype')"
      />
      <div v-if="$v.confirmPassword.$error" class="invalid-feedback">
        <span v-if="!$v.confirmPassword.required">
          {{ $t('account.security.password.process.error.required') }}
        </span>
        <span v-else-if="!$v.confirmPassword.sameAsPassword">
          {{ $t('account.security.password.process.error.match') }}
        </span>
      </div>
    </div>
  </div>
</template>